import React, { useContext, useEffect, useState } from "react";
import { useActionData } from "react-router-dom";
import Icon from "./Icon.js";

const ToastContext = React.createContext();

export default function () {
  return useContext(ToastContext);
}

function getError(o) {
  if (o.validationIssues) return; // Show below field insted
  if (o.message) return o.message.split(": ").pop(); // from Cognito
  return o.error; // from backend
}

export function ToastProvider({ children }) {
  const [on, setOn] = useState();
  const [data, setData] = useState({});
  const error = getError(data);

  useEffect(() => {
    if (error) {
      setOn(true);
      let id = setTimeout(setOn, 4000);
      return () => clearTimeout(id);
    }
  }, [data]);

  const actionData = useActionData();
  useEffect(() => setData(actionData || {}), [actionData]);

  return (
    <ToastContext.Provider value={setData}>
      {children}
      <div
        style={{
          transition: "all 200ms",
          opacity: on && error ? "1" : "0",
          transform: on && error ? "translateY(calc(100% + 1rem))" : "",
          position: "fixed",
          zIndex: 1,
          bottom: "100%",
          right: "1rem",
          width: "20em",
          padding: "1em 2em",
          background: "white",
          borderRadius: "var(--curvy)",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div inert="true" className="toast">
          <h3 style={{ marginBottom: ".5em" }}>Error</h3>
          <p className="error">{error}</p>
        </div>
        <div
          style={{
            position: "absolute",
            top: 6,
            left: 6,
            bottom: 6,
            width: 4,
            background: "var(--error)",
            borderRadius: "var(--curvy)",
          }}
        />
        <Icon
          name="close"
          onClick={(e) => setOn(false)}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            margin: ".5em",
          }}
        />
      </div>
    </ToastContext.Provider>
  );
}
