import { useState, useReducer, useEffect } from "react";
import {
  Link,
  Outlet,
  redirect,
  useLoaderData,
  useParams,
  useRouteLoaderData,
} from "react-router-dom";
import { Breadcrumb, EditForm, getUser } from "./index.js";
import { Artwork, Cards } from "../components/Artwork.js";
import { ConfirmDelete, Confirm, Modal } from "../components/Modal.js";
import Icon from "../components/Icon.js";
import { Image } from "../components/Image.js";
import Menu from "../components/Menu.js";
import MultiSelect from "./components/MultiSelect.js";
import Highlights from "./components/Highlights.js";
import PictureList from "./components/ArtworkPictureList.js";
import { ListHeading, useFilters, uniques } from "./components/List.js";
import { Field, Text, File, Radios, Submit } from "../components/Field.js";
import payload from "../lib/payload.js";
import { api } from "../lib/api.js";

const artworkPathAPI = ({ id }) =>
  `/gallery/${
    id == (id = id.split("!")[0]) ? "artworks" : "draft-artworks"
  }/${id}`;

export default {
  path: "artworks",
  children: [
    {
      path: "",
      element: <ListArtworks />,
      loader: async () => {
        const [published, draft] = await Promise.all([
          api("/gallery/artworks"),
          api("/gallery/draft-artworks"),
        ]);
        return published.concat(draft);
      },
    },
    {
      path: "add",
      element: <EditArtwork />,
      action: async function ({ request }) {
        const o = await api(
          "/gallery/draft-artworks",
          "post",
          await payload(request)
        );
        return o.error ? o : redirect(`/artworks/${o.id}!`);
      },
    },
    {
      id: "artwork",
      path: ":id",
      loader: async ({ params }) => await api(artworkPathAPI(params)),
      children: [
        {
          path: "edit",
          element: <EditArtwork />,
          action: async function ({ request, params }) {
            const o = await api(
              artworkPathAPI(params),
              "put",
              await payload(request)
            );
            return o.error ? o : redirect(`/artworks/${params.id}`);
          },
        },
        {
          path: "",
          element: (
            <>
              <ViewArtwork />
              <Outlet />
            </>
          ),
          children: [
            {
              path: "publish",
              element: (
                <Confirm name="Publish Artwork">
                  <p>
                    Published artworks cannot be deleted or have their previous
                    owners changed.
                  </p>
                </Confirm>
              ),
              action: async function ({ params }) {
                const o = await api(
                  artworkPathAPI(params) + "/publish",
                  "post"
                );
                return o.error
                  ? o
                  : redirect(`/artworks/${params.id.split("!")[0]}`);
              },
            },
            {
              path: "delete",
              element: <ConfirmDeleteArtwork />,
              action: async function ({ params }) {
                const o = await api(artworkPathAPI(params), "delete");
                return o.error ? o : redirect("/artworks");
              },
            },
            {
              path: "ownerships/:ownershipDate",
              element: <AddAttachmentModal />,
              action: attachmentAction,
            },
            {
              path: "ownerships/:ownershipDate/:type",
              element: <ConfirmDeleteAttachment />,
              action: attachmentAction,
            },
            {
              path: "ownerships/:ownershipDate/:type",
              element: <ConfirmDeleteAttachment />,
              action: attachmentAction,
            },

            {
              path: "ownerships/:ownershipDate/:type/:key",
              element: <ConfirmDeleteAttachment />,
              action: attachmentAction,
            },
            {
              path: "picture/:index",
              element: <PictureModal />,
            },
            {
              path: "ownerships",
              element: <OwnershipDialog />,
              action: async function ({ request, params }) {
                const { type, owners, ownershipDate, isUser } = await payload(
                  request
                );
                const o = await api(
                  `${artworkPathAPI(params)}/ownerships`,
                  "post",
                  {
                    ownershipDate: ownershipDate + "T00:00:00Z",
                    owners: owners.map((value) =>
                      isUser ? { type, id: value } : { type, name: value }
                    ),
                  }
                );
                return o.error
                  ? o
                  : isChangeOwnershipToOtherGallery({
                      isUser,
                      owners,
                      type,
                    })
                  ? redirect(`/artworks`)
                  : redirect(`/artworks/${params.id}`);
              },
            },
          ],
        },
      ],
    },
  ],
};

function useArtwork() {
  return useRouteLoaderData("artwork");
}

function ListArtworks() {
  const data = useLoaderData();
  data.map(
    (artwork) => (artwork.status = artwork.draft ? "Draft" : "Published")
  );
  const [items, fields] = useFilters(data, ["status"]);
  items.sort((a, b) => a.title.localeCompare(b.title));
  return (
    <section>
      <ListHeading type="Artwork" />
      {fields}
      {items.length ? <Cards artworks={items} /> : <p>No results</p>}
    </section>
  );
}

function ViewArtwork() {
  const artwork = useArtwork();
  return (
    <>
      <section>
        <div className="row">
          <Breadcrumb subs={[artwork.title]} />
          {artwork.canEdit && (
            <Menu
              button={(open) => (
                <>
                  <button className={"fat group" + (open ? " blue" : "")}>
                    <Icon name="cog" style={{ fill: "none" }} />
                    <span>Options</span>
                    <Icon name="arrow_down" style={{ height: 5 }} />
                  </button>
                </>
              )}
            >
              <Button to="edit" icon="edit">
                Edit
              </Button>
              {artwork.draft ? (
                <>
                  <Button to="ownerships" icon="plus">
                    Ownership
                  </Button>
                  <Button to="publish" icon="upload">
                    Publish
                  </Button>
                  <Button to="delete" icon="bin">
                    Delete
                  </Button>
                </>
              ) : (
                <>
                  <Button to="ownerships" icon="change">
                    Change Owner
                  </Button>
                </>
              )}
            </Menu>
          )}
        </div>
        <Artwork lightbox />
      </section>
    </>
  );
}

function OwnershipDialog() {
  const [collectors, setCollectors] = useState([]);
  const [galleries, setGalleries] = useState([]);

  useEffect(function () {
    (async function () {
      setCollectors(await api("/gallery/collectors"));
      setGalleries(await api("/galleries"));
    })();
  }, []);

  const [isUser, toggleUser] = useReducer((value) => !value, true);
  const [type, setType] = useState("COLLECTOR");
  const [options, setOptions] = useState([]);
  const [owners, setOwners] = useState([]);
  const [ownersDone, setOwnersDone] = useState();

  const { draft } = useArtwork();
  const name = draft ? "Add Previous Ownership" : "Change Ownership";
  return ownersDone ? (
    <Confirm name={name}>
      <p>
        Are you sure you want to make{" "}
        {owners.map((o, i) => (
          <span key={i}>
            {i ? ", " : ""}
            <strong>{o.name}</strong>
          </span>
        ))}{" "}
        the new owner{owners.length > 1 ? "s" : ""}?
      </p>
      {isChangeOwnershipToOtherGallery({ isUser, owners, type }) && (
        <p>You will no longer be able to view this artwork!</p>
      )}
      <input type="hidden" name="type" value={type} />
      <input type="hidden" name="isUser" value="true" />
      {owners.map((owner, i) => (
        <input key={i} type="hidden" name={`owners[${i}]`} value={owner.id} />
      ))}
    </Confirm>
  ) : (
    <Modal>
      <dialog>
        <h2>{name}</h2>
        {draft && (
          <p className="bubble" style={{ textAlign: "left", padding: ".5rem" }}>
            If this is a current sale, you should{" "}
            <Link to="../publish">publish</Link> the artwork first - this way
            the necessary documents will be generated for you.
          </p>
        )}
        {draft && <Text required type="date" name="ownershipDate" />}
        <Field label="Owner Type">
          <select
            name="type"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              setOwners([]);
            }}
          >
            <option value="COLLECTOR">Collector</option>
            <option value="GALLERY">Gallery</option>
          </select>
        </Field>
        <div style={{ position: "relative" }}>
          {isUser ? (
            <MultiSelect
              name="owners"
              prop="id"
              options={
                type == "GALLERY"
                  ? galleries
                  : collectors.filter((o) => o.amlStatus)
              }
              selected={owners}
              setSelected={setOwners}
            />
          ) : (
            <MultiSelect
              name="owners"
              options={options}
              selected={owners}
              setSelected={setOwners}
              setOptions={setOptions}
            />
          )}
          {draft && (
            <div
              className="group"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <small>Artunum user</small>
              <input
                className="field"
                type="checkbox"
                name="isUser"
                checked={isUser}
                onChange={(e) => {
                  toggleUser();
                  setOwners([]);
                }}
              />
            </div>
          )}
        </div>
        {draft ? (
          <Submit text="Add Ownership" disabled={owners.length < 1} />
        ) : (
          <button
            type="button"
            className="commit fat"
            disabled={owners.length < 1}
            onClick={(e) => setOwnersDone(true)}
          >
            Continue
          </button>
        )}
      </dialog>
    </Modal>
  );
}

function EditArtwork() {
  const [options, setOptions] = useState([]);
  const [artists, setArtists] = useState([]);
  artists.sort((a, b) => a.name.localeCompare(b.name));
  useEffect(function () {
    (async function () {
      setOptions(uniques(await api("/gallery/artworks"), "medium"));
      setArtists(await api("/gallery/artists"));
    })();
  }, []);

  const data = useArtwork() || {};
  const [selected, setSelected] = useState(data.medium || []);
  const fd = (name) => ({
    name,
    defaultValue: data[name],
  });

  return (
    <EditForm>
      <Breadcrumb subs={data.id ? [data.title, "Edit"] : ["Add"]} />
      <div>
        <label>Images</label>
        <PictureList {...data} />
      </div>
      <Text {...fd("title")} />
      <Field label="Artist" name="artistId">
        {data.id ? (
          <>
            <input readOnly value={data.artistName} />
            <input type="hidden" name="artistId" value={data.artistId} />
          </>
        ) : (
          <select required name="artistId">
            <option value="">Select Artist</option>
            {artists.map((a, i) => (
              <option key={i} value={a.id}>
                {a.name}
              </option>
            ))}
          </select>
        )}
      </Field>
      <Field label="Description">
        <textarea name="description" defaultValue={data.description} />
      </Field>
      <Text required {...fd("creationYear")} />
      <Text required {...fd("dimension")} />
      <Text {...fd("price")} />
      <Text {...fd("storageRef")} />
      <Radios
        name="frameStatus"
        value={data.frameStatus}
        values={["Framed", "Frame Pending", "N/A"]}
      />
      <Radios
        name="availability"
        value={data.availability}
        values={"AVAILABLE UNAVAILABLE ON_HOLD SOLD".split(" ")}
      />
      <MultiSelect
        name="medium"
        setOptions={setOptions}
        setSelected={setSelected}
        selected={selected}
        options={options}
      />
      <Highlights artwork={data} />
    </EditForm>
  );
}

function isChangeOwnershipToOtherGallery({ isUser, type, owners }) {
  // Not a current owner (draft artwork):
  if (!isUser) return false;

  // Presumably a collector in this gallery:
  if (type != "GALLERY") return false;

  // New gallery might be the current one (gallery buys back from collector)
  // See if the current user belongs to this group (actually an admin can only belong to one gallery):
  const user = getUser();
  return !owners.find((id) => user[id]);
}

function AddAttachmentModal() {
  const artwork = useArtwork();
  const types = ["attachment", "lop", "coa"];
  const [type, setType] = useState(types[0]);
  return (
    <Modal encType="multipart/form-data">
      <dialog
        onChange={(e) => {
          const { type } = Object.fromEntries(new FormData(e.target.form));
          setType(type);
        }}
      >
        <h2>Add Attachment</h2>
        <File required name="file" />
        {artwork.draft ? (
          <Radios name="type" value={type} values={types} />
        ) : (
          <input type="hidden" name="type" value="attachment" />
        )}
        {type == types[0] && <Text required name="name" />}
        <Submit text="Add Attachment" />
      </dialog>
    </Modal>
  );
}

function ConfirmDeleteAttachment() {
  const artwork = useArtwork();
  const params = useParams();
  const ownership = artwork.provenance.find(
    (o) => o.ownershipDate == params.ownershipDate
  );
  return ConfirmDelete(
    params.type == "coa"
      ? { name: "Certificate of Authenticity" }
      : params.type == "lop"
      ? { name: "Letter of Provenance" }
      : ownership.attachments[params.key]
  );
}

function ConfirmDeleteArtwork() {
  const artwork = useArtwork();
  return ConfirmDelete(artwork);
}

async function attachmentAction({ request, params }) {
  const formData = await request.formData();
  const { type, name } = Object.fromEntries(formData);

  const path = `${artworkPathAPI(params)}/ownerships/${
    params.ownershipDate
  }/docs/${params.type || type}/${encodeURIComponent(
    name || params.key || ""
  )}`;

  const o = await api(path, request.method, formData);
  return o.error ? o : redirect(`/artworks/${params.id}`);
}

function Button({ to, icon = "change", children }) {
  return (
    <Link to={to} className="iconText">
      <Icon name={icon} />
      {children}
    </Link>
  );
}

function PictureModal() {
  const artwork = useArtwork();
  const { index } = useParams();
  const [busy, setBusy] = useState(true);
  return (
    <Modal>
      <div style={{ background: "white", padding: 2 }}>
        {busy && (
          <span
            className="spinner"
            style={{ fontSize: "1rem", margin: "2rem" }}
          />
        )}
        <Image
          id={"artwork/" + artwork.id + "/" + artwork.pictureList[index]}
          onLoad={(e) => setBusy(false)}
          style={{
            width: "auto",
            borderRadius: 0,
            maxWidth: "calc(100vw - 4rem)",
            maxHeight: "calc(100vh - 4rem)",
          }}
        >
          calc(100vw - 4rem)
        </Image>
      </div>
    </Modal>
  );
}
