export const galleryName = (groupName) =>
  groupName.split("-").slice(1).join(" ");

export const ADDRESS =
  "addressLine1 addressLine2 city state postcode country".split(" ");

export const address = (person) =>
  ADDRESS.map((prop) => person[prop])
    .filter((value) => value)
    .join(", ");

// Human-readable forms of property names used by our api:
const Special = {
  lop: "Letter of Provenance",
  coa: "Certificate of Authenticity",
  representDate: "Represented Since",
};

export const humanize = (name) =>
  Special[name] ||
  name.charAt(0).toUpperCase() +
    name.slice(1).replace(/[A-Z1-9]/g, (c) => " " + c);
//replace(/-[A-Z]/g)

export function parseDate(text) {
  const [Y, m, d] = text.split("-");
  const date = new Date();
  try {
    date.setYear(parseInt(Y));
    date.setMonth(parseInt(m) - 1);
    date.setDate(parseInt(d));
    return date;
  } catch (e) {}
}

export function formatDate(text) {
  const d = parseDate(text);
  const m = d.toLocaleString("default", { month: "long" });
  const Y = d.getFullYear();
  const D = d.getDate();
  return D + nth(D) + " " + m + " " + Y;
}
function nth(d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
